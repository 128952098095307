import moment from 'moment';
import HomeController from './HomeController';
import { Settings } from '../common/Settings';


function initSlider() {
  var swiperSlider = new Swiper('.swiper-parent',{
    paginationClickable: false,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    onSwiperCreated: function(swiper){
        $('[data-caption-animate]').each(function(){
            var $toAnimateElement = $(this);
            var toAnimateDelay = $(this).attr('data-caption-delay');
            var toAnimateDelayTime = 0;
            if( toAnimateDelay ) { toAnimateDelayTime = Number( toAnimateDelay ) + 750; } else { toAnimateDelayTime = 750; }
            if( !$toAnimateElement.hasClass('animated') ) {
                $toAnimateElement.addClass('not-animated');
                var elementAnimation = $toAnimateElement.attr('data-caption-animate');
                setTimeout(function() {
                    $toAnimateElement.removeClass('not-animated').addClass( elementAnimation + ' animated');
                }, toAnimateDelayTime);
            }
        });
        SEMICOLON.slider.swiperSliderMenu();

    },
    onSlideChangeStart: function(swiper){
        $('[data-caption-animate]').each(function(){
            var $toAnimateElement = $(this);
            var elementAnimation = $toAnimateElement.attr('data-caption-animate');
            $toAnimateElement.removeClass('animated').removeClass(elementAnimation).addClass('not-animated');
        });
        SEMICOLON.slider.swiperSliderMenu();
    },
    onSlideChangeEnd: function(swiper){
        $('#slider').find('.swiper-slide').each(function(){
            if($(this).find('video').length > 0) { $(this).find('video').get(0).pause(); }
            if($(this).find('.yt-bg-player').length > 0) { $(this).find('.yt-bg-player').pauseYTP(); }
        });
        $('#slider').find('.swiper-slide:not(".swiper-slide-active")').each(function(){
            if($(this).find('video').length > 0) {
                if($(this).find('video').get(0).currentTime != 0 ) $(this).find('video').get(0).currentTime = 0;
            }
            if($(this).find('.yt-bg-player').length > 0) {
                $(this).find('.yt-bg-player').getPlayer().seekTo( $(this).find('.yt-bg-player').attr('data-start') );
            }
        });
        if( $('#slider').find('.swiper-slide.swiper-slide-active').find('video').length > 0 ) { $('#slider').find('.swiper-slide.swiper-slide-active').find('video').get(0).play(); }
        if( $('#slider').find('.swiper-slide.swiper-slide-active').find('.yt-bg-player').length > 0 ) { $('#slider').find('.swiper-slide.swiper-slide-active').find('.yt-bg-player').playYTP(); }

        $('#slider .swiper-slide.swiper-slide-active [data-caption-animate]').each(function(){
            var $toAnimateElement = $(this);
            var toAnimateDelay = $(this).attr('data-caption-delay');
            var toAnimateDelayTime = 0;
            if( toAnimateDelay ) { toAnimateDelayTime = Number( toAnimateDelay ) + 300; } else { toAnimateDelayTime = 300; }
            if( !$toAnimateElement.hasClass('animated') ) {
                $toAnimateElement.addClass('not-animated');
                var elementAnimation = $toAnimateElement.attr('data-caption-animate');
                setTimeout(function() {
                    $toAnimateElement.removeClass('not-animated').addClass( elementAnimation + ' animated');
                }, toAnimateDelayTime);
            }
        });
    }
});

$('#slider-arrow-left').on('click', function(e){
    e.preventDefault();
    swiperSlider.swipePrev();
});

$('#slider-arrow-right').on('click', function(e){
    e.preventDefault();
    swiperSlider.swipeNext();
});

function loopSwiper(){
    swiperSlider.swipeNext();      
}
}
function initPartnersCarusel() {
  var cl = $('#partners-block');
  var params = {
      items: 18,
      margin: 30,
      loop: true,
      nav: false,
      navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
      autoplay: true,
      autoplayHoverPause: false,
      dots: true,
      navRewind: false,
      responsive: {
          0: {items: 2},
          480: {items: 3},
          768: {items: 4},
          992: {items: 5},
          1200: {items: 6}

      }
  };

  cl.owlCarousel(params);
}

function initPortfolio() {
  var $container = $('#portfolio');

  $container.isotope({ transitionDuration: '0.65s' });

  $('#portfolio-filter a').click(function(){
      $('#portfolio-filter li').removeClass('activeFilter');
      $(this).parent('li').addClass('activeFilter');
      var selector = $(this).attr('data-filter');
      $container.isotope({ filter: selector });
      return false;
  });

  $('#portfolio-shuffle').click(function(){
      $container.isotope('updateSortData').isotope({
          sortBy: 'random'
      });
  });

  $(window).resize(function() {
      $container.isotope('layout');
  });
}

function initGoogleMap() {
  if ($('#google-map').length){
    $('#google-map').gMap({
        address: 'Novovagankovskiy per, 5c1 , Moscow, Russia',
        maptype: 'ROADMAP',
        zoom: 15,
        markers: [
            {
                address: "Novovagankovskiy per, 5c1 , Moscow, Russia",
                html: '',
                icon: {
                    image: "images/icons/map-icon-red.png",
                    iconsize: [32, 39],
                    iconanchor: [32,39]
                }
            }
        ],
        doubleclickzoom: false,
        controls: {
            panControl: true,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false
        }
   });
  }  
}

document.addEventListener('DOMContentLoaded', () => {
  moment.locale(Settings.language());
  const controller = new HomeController();
  controller.init();
  initSlider();
  initPartnersCarusel();
  initPortfolio();
});
