import jQuery from 'jquery';
import toastr from 'toastr';
import { Settings } from '../common/Settings';

require('es6-promise').polyfill();
const axios = require('axios');

const URL_SUBSCRIPTION = `${Settings.BASE_URL()}/api/site/subscription`;
const URL_SEND_MESSAGE = `${Settings.BASE_URL()}/api/site/contact`;
const URL_CHECKTOKEN = `${Settings.BASE_URL()}/api/auth/checktoken`;
const RECAPTCHA_SITE_KEY = '6Lca47gUAAAAAHLA-niOKgfBbwtQUG2Y-9KU5m4n';


export default class HomeController {
  constructor() {
    this.subscribeButton = jQuery('.subscribe__button');
    this.email = jQuery('#widget-subscribe-form1-email');
    this.sendMessageButton = jQuery('.send_message__button');
    this.contactForm = jQuery('#template-contactform');
    this.loginLink = jQuery('.login__link');
    this.lkLink = jQuery('.lk__link');
    this.subscribeFormModal = $('#subscribeFormModal');
    this.widgetSubscribeForm = $($('.widget-subscribe__form').get(0));

    grecaptcha.ready(() => {
      this.recaptchaWidget = grecaptcha.render('recaptcha_subscribe__container', {
        sitekey: RECAPTCHA_SITE_KEY,
        callback: 'recaptchaCallback',
      });

      this.recaptchaSendMessageWidget = grecaptcha.render('recaptcha_sendmessage__container', {
        sitekey: RECAPTCHA_SITE_KEY,
      });
    });
    this.lastGoal = '';
  }

  init() {
    this.bindClick();
    this.bindSendMessage();
    this.bindCaptcha();
    this.initLk();
    this.intiVideo();
    this.bindYM();
  }

  bindCaptcha() {
    const recaptchaCallback = () => {
      if (this.email.val() && this.widgetSubscribeForm.valid()) {
        this.subscription();
      } else {
        toastr.error('invalid email');
      }
      grecaptcha.reset(this.recaptchaWidget);
      this.subscribeFormModal.modal('hide');
    };

    window.recaptchaCallback = recaptchaCallback;
  }

  initLk() {
    if (this.checkAuth()) {
      axios.post(URL_CHECKTOKEN, this.info)
        .then((response) => {
          if (response.data) {
            this.lkLink.show();
          } else {
            this.loginLink.show();
          }          
        })
        .catch((error) => {
          this.loginLink.show();
          console.log(error);
        });
    } else {
      this.loginLink.show();
    }
  }

  intiVideo() {
    jQuery(window).resize(function() {
      var t = setTimeout( function(){
          SEMICOLON.initialize.blogTimelineEntries();
      }, 2500 );
    });
  }

  bindClick() {
    const instance = this;
    function subscribeButtonClick() {
      instance.email = jQuery(jQuery(this).closest('.input-group-btn').prevAll('.subscribe_email__input').get(0));
      instance.widgetSubscribeForm = $($(this).closest('.widget-subscribe__form').get(0));
    }

    this.subscribeButton.click(subscribeButtonClick);
  }

  bindYM() {
    this.sendMessageButton.click(() => {
      this.lastGoal = 'massage';
    });

    $('.request__button__gisarm').click(() => { 
      this.lastGoal = 'zayavka_apm';
    });

    $('.request__button__gisweb').click(() => { 
      this.lastGoal = 'zayavka_web';
    })

  }

  subscription() {
    axios.post(URL_SUBSCRIPTION,
      {
        email: this.email.val(),
        recaptchaResponse: grecaptcha.getResponse(this.recaptchaWidget),
      })
      .then(() => {
        this.email.val('');
        ym('90362079','reachGoal', 'novosti');
        toastr.success('На указанную Вами почту отправлено письмо');
      })
      .catch((error) => {
        toastr.error(error.response.data.Message);
      });
  }

  bindSendMessage() {
    jQuery('#template-contactform').submit(() => {
      if ($('#template-contactform').valid()) {
        this.sendMessage();
        ym('90362079','reachGoal', this.lastGoal);
        $('.modal').fadeOut();
      }
      return false;
    });
  }

  sendMessage() {
    axios.post(`${URL_SEND_MESSAGE}?captchaResponse=${grecaptcha.getResponse(this.recaptchaSendMessageWidget)}`,
      {
        Name: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'first-name'; })[0].value,
        Organization: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'organization'; })[0].value,
        Post: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'post'; })[0].value,
        Phone: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'phone'; })[0].value,
        Email: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'email'; })[0].value,
        ContactType: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'category'; })[0].value,
        Title: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'subject'; })[0].value,
        Text: jQuery('#template-contactform').serializeArray().filter((f) => { return f.name == 'message'; })[0].value,
        URL: window.location.href,
        Referrer: document.referrer
      })
      .then(() => {
        this.contactForm.find('.sm-form-control').val('');
        toastr.success('Сообщение успешно отправлено');
        grecaptcha.reset(this.recaptchaSendMessageWidget);
      })
      .catch((error) => {
        this.contactForm.find('.sm-form-control').val('');        
        toastr.error(error.response.data.Message);
        grecaptcha.reset(this.recaptchaSendMessageWidget);
      });
  }

  checkAuth() {
    const test = localStorage.getItem('mapmaker');
    if (test) {
      this.info = JSON.parse(test);
      return true;
    }
    return false;
  }
}
