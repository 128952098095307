// eslint-disable-next-line import/prefer-default-export
export class Settings {
  static language() {
    return document.documentElement.lang.substr(0, 2).toLowerCase();
  }

  static BASE_URL() {
    return '';
  }

  static get Format() {
    return 'DD.MM.YYYY HH:mm';
  }
}
